@import "../../styles/global.scss";

.footer {
  background-color: #303435;
  color: $white;
  min-height: 60px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  margin-top: 150px;
}
