@import "../../styles/global.scss";

.topNav{
  background-color: $black;
  color: $white;
}

.mobileMenu{
  background-color: $grey;
}

.subMenuTitle{
  font-size: 14px;
}



.topNavIcon{
  width: 30px !important;
  height: 30px !important;
  border-radius: 15px;
  background-color: $pink !important;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  .fa{
    font-size: 36px;
  }
  transition: opacity .5s;
  &:hover{
    opacity: .7
  }
}

.topNavText{
  color: $pink;
  font-size: 16px;
  font-weight: bold;
}
.searchBar {

  padding: 0 12px;
  width: 340px;
  height: 42px;
  border: 1px solid #CCCCCC;
  border-radius: 0;
  
}

.clearSearch{
  cursor: pointer;

    &:hover{
      opacity: .7;
    }
  }

.searchInput {
  border: none;
  outline: none;
  &:focus{
    border: none;
  }
  width: 290px;
  height: 36px;
  padding-left: 8px;
}

.menuItem{
  display: flex;
  justify-content: center;
  align-items: center;

}

.cartBadge{
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: $pink;
  color: $white;
  font-size: 12px;
  top: 0;
  right: 0;
  margin-top: -10px;
  margin-right: -2px;
}

.cart{
  position: relative;
}

.borderLeft{
 border-left: 1.5px solid $pink;
 padding-left: 6px;



}

.borderBottom{
  padding-bottom: 4px;
  border-bottom: 1.5px solid $pink;
}
.menuLabel{
  font-size: 13px;
  font-weight: 600;
  margin-left: 4px;
}





@media (max-width: $media-lg) {
  
}


.slideButton{
  outline: none;
  border: none;
  i{
    font-size: 26px !important;
  }

  background-color: transparent !important;
}

.menuBar{
  width: 95%;
  overflow-x: auto;

}



.menuBar {
  position: relative;
}

.menuList {
  display: flex;
  flex-direction: row;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 250px;
    background-color: #f8f9fa;
    padding-top: 60px;
    transform: translateX(-100%);
    z-index: 1000;

    &.menuOpen {
      transform: translateX(0);
    }
  }
}

.menuItem {
  display: flex;
  align-items: center;
  padding: 10px 4px;
  cursor: pointer;
  transition: color 0.5s;
  &:hover {
    background-color: #e9ecef;
    color: $pink;
  }
}

.menuLabel {
  margin-left: 5px;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
  @media (max-width: 768px) {
    display: flex;
  }
}

.hamburgerLine {
  width: 25px;
  height: 3px;
  background-color: #000;
  margin: 4px 0;
}
