@import "../../styles/global.scss";

.title{
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0 20px 0;
    padding: 4px 30px;
    span{
        text-transform: uppercase;
        font-size: 26px;
        font-weight: 700;
        margin-left: 20px;
    }
    img{
        width: 32px;
        height: 32px;
    }
   
}

.divider{
    width: 54vw;
    height: 2px;
    background-color: $pink
}


@media (max-width: $media-sm) {
    .title{
      
        span{
            font-size: 20px;
          
        }}
  }
  