@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600&display=swap");
@import "./styles/global.scss";

html {
  position: relative;
  min-height: 100%;
  overflow-x: hidden !important;

}

.app {
  position: relative;
  overflow-x: hidden !important;
}

a{
  text-decoration: none !important;
  color: inherit !important;
}

// .main {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

@media (max-width: $media-md) {
  .carousel {
    .carousel-caption {
      top: 30%;
    }
  }
}

.control-next{
  width: 36px;
  height: 36px;
  position: absolute !important;
  margin-top: 150px !important;
  background-color: $pink !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px !important;
  margin-right: 30px !important;
  opacity: 1 !important;
  border-radius: 50%;
}

.control-prev{
  width: 36px;
  height: 36px;
  position: absolute !important;
  margin-top: 150px !important;
  background-color: $pink !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px !important;
  margin-left: 30px !important;
  opacity: 1 !important;
  border-radius: 50%;
}

.product-price{
  
    font-size: 18px !important;
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: 600 !important;
    color: $pink !important;
  
  
}

.btn-success{
  background-color: $green !important;
  transition: all 0.6s;
  &:hover{
    opacity: .7;
  }
}

.text-pink{
  color: $pink !important;

}

.btn-secondary{
  background-color: $pink !important;
  transition: all 0.6s;
  &:hover{
    opacity: .7;
  }
}

.ant-card-meta-title{
  font-size: 12px !important;
}

.ant-card-meta-description{
  font-size: 18px;
  font-weight: 800;
  opacity: 1;
  color: $pink !important;
  text-align: center;
}

.ant-card-body{
  padding: 20px 10px !important;
  text-align: center !important;
}