@import "../../styles/global.scss";



.categoryItem{
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.categoryName{
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 18px;
  font-weight: bolder;
  margin-bottom: 6px;
}

.categoryIcon{
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 2px solid $pink;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  img{
    width: 46px;
    height: 46px;
  }


}

@media (max-width: $media-md) {
  
}

@media (min-width: 768px) and (max-width: $media-lg) {
 
}
