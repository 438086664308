@import "../../styles/global.scss";

.cartCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-shadow: 1px 1px 7px #d3d3d3;
  margin-bottom: 30px;
  border-radius: 4px;
  transition: all 0.4s ease-in-out;

  &:hover {
    transform: scale(1.005);
    transform: all 0.4s ease-in-out;
  }

  :global {
    .cartBtns {
      align-items: center;
      display: flex;
      gap: 20px;
    }

    .btn-danger {
      padding: 2px 15px;
    }
  }
}

.cartBtn {
  background-color: $nevyBlue;
  border: none;
  border-radius: 2px;
  color: $white;
  padding: 2px 15px;
}

@media (max-width: $media-md) {
  .cartCard {
    flex-direction: column;
    padding: 30px 20px;
    gap: 20px;
    text-align: center;
  }
}
