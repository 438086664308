@import "./colors.scss";
@import "./constant.scss";

body {
  font-family: "Raleway", sans-serif;
}

@media (max-width: $media-md) {
  h3,
  h4,
  h5,
  p,
  h2 {
    text-align: center;
  }

  .navbar-light {
    .navbar-toggler {
      border-color: $white !important;
    }

    .navbar-toggler-icon {
      background-color: $white;
    }
  }
}
