@import "../../styles/global.scss";

.container {
  padding-bottom: 70px;
}

.emptyCart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  :global {
    img {
      max-width: 700px;
      width: 100%;
      height: auto;
    }
  }
}
