.instagramContainer {
    background: #FFF;
    border-radius: 3px;
    box-shadow: 0 0 1px 0 rgba(0,0,0,0.5), 0 1px 10px 0 rgba(0,0,0,0.15);
    margin: 1px;
    max-width: 540px;
    min-width: 326px;
    padding: 0;
    width: 99.375%;
    width: -webkit-calc(100% - 2px);
    width: calc(100% - 2px);
  }
  
  .instagramMedia {
    padding: 16px;
  }
  
  .instagramContent {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .instagramCaption {
    color: #c9c8cd;
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0;
    margin-top: 8px;
    overflow: hidden;
    padding: 8px 0 7px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .instagramCaption a {
    color: #c9c8cd;
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 17px;
    text-decoration: none;
  }
  .selectedVideo {
    border: 4px solid #d52ab4 !important; // Add a border to the selected video
  }
  